// src/components/Staff.js
import React, { useState, useEffect } from 'react';
import '../styles/Staff.css';

const publicUrl = process.env.PUBLIC_URL;

const infoStaffDetails = {
    'Manny Lastras': {
        titulo: 'Lic. Manuel E. Glez. Lastras',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Docencia en el idioma inglés</p>
            <p>👨🏻‍🏫Especialidad en Adultos CELTA</p>
            <p>👨🏻‍🏫Diplomado DELTE</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>Capacitador de TKT,PET,KET,FLYERS,TOEFL</p>
            <p>Cuenta con TKT 1,2,3</p>
            <p>Años de experiencia: 20 años</p>
            <p>💼Cargo en SPEAKLAND: Director General</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/manny.png`
    },
    /* 'Geraldy Jiménez': {
        titulo: 'Lic. Geraldy Jiménez',
        contenido: `
            <hr>
            <p>🎓licenciatura en docencia del idioma ingles.</p>
            <p>TKT 1,2,3</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>👨🏻‍🏫Certification: IELTS, CAE</p>
            <p>Años de experiencia: 12 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/Geraldy2.jpeg`
    }, */
    /* 'Jerry Cámara': {
        titulo: 'Lic. Gerardo García Cámara',
        contenido: `
            <hr>
            <p>🎓Comunicación y Administración Corporativa</p>
            <p>Coach AICTRAP (Asociación Internacional de Coaches en Transformación Personal)</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>👨🏻‍🏫Teacher de grupos personalizados y conversacionales avanzados</p>
            <p>Años de experiencia: 10</p>
            <p>💼Cargo en Speakland: Teacher / Vinculación y publicidad</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/jerry.png`
    }, */
    'Gerardo García': {
        titulo: 'Lic. Gerardo García Miguel',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Sistemas Computacionales</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>CENNI 15</p>
            <p>TKT 1,2,3</p>
            <p>📃CAE</p>
            <p>Años de experiencia: 15 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher de niveles avanzados</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/gerardo1.png`
    },
    'Lupita Flores': {
        titulo: 'Lic. Guadalupe Susana Flores Dominguez',
        contenido: `
            <hr>
            <p>🎓Lic. Formación Docente tipo medio Superior énfasis Inglés</p> 
            <p>CENNI <b>10</b></p>
            <p>TKT 1,2,3</p>
            <p>ITEP</p>
            <p>Aptis</p>
            <p>Nivel <b>B1</b></p>
            <p>Diplomado en Metodología en la Enseñanza del idioma Inglés (CAM)</p>
            <p>Años de experiencia: 5 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/lupita.png`
    },
    'Alejandra Valdez': {
        titulo: 'Lic. Zulema Alejandra Valdez Guillen',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Enseñanza del Idioma Inglés</p>
            <p>🎓Lic. en enseñanza del idioma inglés</p>
            <p>CENNI <b>12</b></p>
            <p>TKT 1,2,3</p>
            <p>TKT young learners <b>C1</b></p>
            <p>Diplomado DELTE <b>B2</b></p>
            <p>Nivel <b>B2</b></p>
            <p>Años de experiencia: 8 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher para niños y adultos</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/alejandra.png`
    },
    'Cielo Berrelleza': {
        titulo: 'Lic. Cielo Anahí Berrelleza',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Intervención Educativa Inclusiva</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>B1</b></p>
            <p>Años de experiencia: 2 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/cielo.jpeg`
    },
    'Paul Salas': {
        titulo: 'M.I. Paul R. Salas Hernández',
        contenido: `
            <hr>
            <p>🎓Maestría en Ingeniería: Gestión ambiental y eficiencia energética</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>Años de experiencia: 3 años</p>
            <p>💼Cargo en SPEAKLAND: Coordinador del programa para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/paul.jpeg`
    },
    'Ariadna Espinoza': {
        titulo: 'Ariadna Espinoza',
        contenido: `
            <hr>
            <p>Estudiante de Licenciatura en Enseñanza del Idioma Inglés</p>
            <p>Especialidad en young learners</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>Años de experiencia: 3 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/ariadna.jpg`
    },
    'Edgar Dorantes': {
        titulo: 'Lic. Edgar Ricardo Dorantes Vértiz',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Comunicación</p>
            <p>🎓Maestro en Educación en Idioma Inglés</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>TKT 1,2,3</p>
            <p>EILTS</p>
            <p>Años de experiencia: 22 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher de adultos preparación PET</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/edgar.jpeg`
    },
    'José Gil': {
        titulo: 'Ing. José Erubiel Gil Chaparro ',
        contenido: `
            <hr>
            <p>🎓Ingeniería industrial </p>
            <p>📚NIVEL <b>B2</b></p>
            <p>FCE</p>
            <p>Años de experiencia: 3 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher para niños y adultos</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/gil.png`
    },
    'Alejandra Rubio': {
        titulo: 'Lic. Alejandra Miranda Rubio ',
        contenido: `
            <hr>
            <p>🎓Lic en educación</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>IELTS</p>
            <p>TKT 1,2,3</p>
            <p>Años de experiencia: 5 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher para niños</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/miranda.png`
    },
    'Mariajosé Avena': {
        titulo: 'María José Avena Martínez',
        contenido: `
            <hr>
            <p>Estudiante de preparatoria</p>
            <p>📃PET</p>
            <p>📚NIVEL <b>B1</b></p>
            <p>💼Cargo en SPEAKLAND: Teacher Aide</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/avena.png`
    },
    'Emmanuel Conde': {
        titulo: ' Lic. Emmanuel Conde Valenzuela',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Docencia en el idioma inglés</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>IELTS</p>
            <p>FCE</p>
            <p>CELTA</p>
            <p>Años de experiencia: 6 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/emanuel.png`
    },
    'Georgina Osorio': {
        titulo: 'Lic. Georgina Osorio',
        contenido: `
            <hr>
            <p>🎓Certificado A: Licenciatura en Docencia en el idioma inglés, University of Alabama & Universidad de Tlaxcala.</p>
            <p>📚AA – General Arts and Sciences, Malcolm X College, Chicago, Il.</p>
            <p>📚NIVEL <b>C1</b></p>
            <p>Capacitador: ESL, TOEFL</p>
            <p>Examinador Certificado TOEIC.</p>
            <p>TKT 1,2,3</p>
            <p>Años de experiencia: 35 años</p>
            <p>💼Cargo en SPEAKLAND: Coordinador del programa para adultos.</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/gina.jpeg`
    },
    'Rocio Angulo': {
        titulo: 'Rocio Angulo Alvarez',
        contenido: `
            <hr>
            <p>🎓Licenciatura en Mercadotecnia y publicidad</p>
            <p>📃Curso: Gestión organizacional </p>
            <p>💼Cargo en SPEAKLAND: Practicante de Mkt</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/rocio.jpeg`
    },
    'Manuel Corrales': {
        titulo: 'Lic. José Manuel Corrales Delgado',
        contenido: `
            <hr>
            <p>🎓Psicología Social Comunitaria</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>📃PET</p>
            <p>Años de experiencia: 6 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/manuel.jpg`
    },
    'Lorena Peña': {
        titulo: ' Mtra. Lorena Peña Hernández',
        contenido: `
            <hr>
            <p>🎓Maestría en educación</p>
            <p>📚NIVEL <b>B1</b></p>
            <p>📃PET</p>
            <p>Años de experiencia: 5 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/lorena.jpg`
    },
    'Ivone Domínguez': {
        titulo: ' Lic. Silvia Ivone Domínguez Pacheco',
        contenido: `
            <hr>
            <p>🎓licenciatura en docencia en el idioma inglés</p>
            <p>📚NIVEL <b>B2</b></p>
            <p>📃FCE</p>
            <p>Años de experiencia: 2 años</p>
            <p>💼Cargo en SPEAKLAND: Teacher</p>
        `,
        imagenUrl: `${publicUrl}/media/staff_img/ivone.jpg`
    },
};

const Staff = () => {
    const [selectedMember, setSelectedMember] = useState(null);

    const handleCardClick = (name) => {
        setSelectedMember(name);
        window.history.pushState({ modalOpen: true }, '', `#modal-${name}`); // Agrega un estado único
    };
    

    const handleCloseModal = () => {
        setSelectedMember(null);
        if (window.history.state && window.history.state.modalOpen) {
            window.history.back();
        }
    };

    const handleModalClick = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseModal();
        }
    };

    useEffect(() => {
        const handlePopState = () => {
            if (selectedMember !== null) {
                handleCloseModal(); 
            }
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [selectedMember]);

    return (
        <div className="staff-container">
            <div className="staff-background" data-aos="zoom-in">
                <div className="staff-overlay">
                    <h1 className="staff-title">CONOCE A NUESTRO STAFF</h1>
                </div>
            </div>
            <p className="staff-subtitle" data-aos="zoom-in">
                - We are a group of innovating, experienced, and proficient teachers you will love to learn from. <br /> We also think that, the service is more important than the sale. -
            </p>
            <br />
            <br />
            <h2 className="staff-section-title" data-aos="flip-up">Miembros administrativos / Admin Staff</h2>
            <div className="staff-row" data-aos="zoom-in">
                <div className="staff-card" onClick={() => handleCardClick('Manny Lastras')}>
                    <img src={`${publicUrl}/media/staff_img/manny.png`} alt="Manny Lastras" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Manny</h3>
                        <h4 className="staff-card-surname">Lastras</h4>
                        <p className="staff-card-role"><b>CEO</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('Paul Salas')}>
                    <img src={`${publicUrl}/media/staff_img/paul.jpeg`} alt="Paul Salas" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Paul</h3>
                        <h4 className="staff-card-surname">Salas</h4>
                        <p className="staff-card-role"><b>Coordinador</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('Georgina Osorio')}>
                    <img src={`${publicUrl}/media/staff_img/gina.jpeg`} alt="Georgina Osorio" className="staff-image"
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} 
                    />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Georgina</h3>
                        <h4 className="staff-card-surname">Osorio</h4>
                        <p className="staff-card-role"><b> Coordinador</b></p>
                    </div>
                </div>

                <div className="staff-card" onClick={() => handleCardClick('Rocio Angulo')}>
                    <img src={`${publicUrl}/media/staff_img/rocio.jpeg`} alt="Rocio Angulo" className="staff-image"
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} 
                    />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Rocio</h3>
                        <h4 className="staff-card-surname">Angulo</h4>
                        <p className="staff-card-role"><b>Practicante</b></p>
                    </div>
                </div>
            </div>

            <h2 className="staff-section-title" data-aos="flip-up">Docentes / Teachers</h2>
            <div className="staff-row" data-aos="zoom-in">
                {/* <div className="staff-card" onClick={() => handleCardClick('Jerry Cámara')}>
                    <img src={`${publicUrl}/media/staff_img/jerry.png`} alt="Jerry Cámara" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Jerry</h3>
                        <h4 className="staff-card-surname">Cámara</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div> */}

                {/* <div className="staff-card" onClick={() => handleCardClick('Geraldy Jiménez')}>
                    <img src={`${publicUrl}/media/staff_img/Geraldy2.jpeg`} alt="Geraldy Jiménez" className="staff-image" style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Geraldy</h3>
                        <h4 className="staff-card-surname">Jiménez</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div> */}

                <div className="staff-card" onClick={() => handleCardClick('Gerardo García')}>
                    <img src={`${publicUrl}/media/staff_img/gerardo1.png`} alt="Gerardo García" className="staff-image"
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }}
                    />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Gerardo</h3>
                        <h4 className="staff-card-surname">García</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>

                {/* <div className="staff-card" onClick={() => handleCardClick('Ariadna Espinoza')}>
                    <img src={`${publicUrl}/media/staff_img/ariadna.jpg`} alt="Ariadna Espinoza" className="staff-image" style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Ariadna</h3>
                        <h4 className="staff-card-surname">Espinoza</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div> */}
                {/* <div className="staff-card" onClick={() => handleCardClick('Edgar Dorantes')}>
                    <img src={`${publicUrl}/media/staff_img/edgar.jpeg`} alt="Edgar Dorantes" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Edgar</h3>
                        <h4 className="staff-card-surname">Dorantes</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div> */}
                <div className="staff-card" onClick={() => handleCardClick('José Gil')}>
                    <img src={`${publicUrl}/media/staff_img/gil.png`} alt="José Gil" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">José</h3>
                        <h4 className="staff-card-surname">Gil</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>
                {/* <div className="staff-card" onClick={() => handleCardClick('Alejandra Rubio')}>
                    <img src={`${publicUrl}/media/staff_img/miranda.png`} alt="Alejandra Rubio" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Alejandra</h3>
                        <h4 className="staff-card-surname">Rubio</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div> */}
                <div className="staff-card" onClick={() => handleCardClick('Emmanuel Conde')}>
                    <img src={`${publicUrl}/media/staff_img/emanuel.png`} alt="Emmanuel Conde" className="staff-image"
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} 
                    />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Emmanuel</h3>
                        <h4 className="staff-card-surname">Conde</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>
            </div>

            <div className="staff-row" data-aos="zoom-in">
                
                <div className="staff-card" onClick={() => handleCardClick('Manuel Corrales')}>
                    <img src={`${publicUrl}/media/staff_img/manuel.jpg`} alt="Manuel Corrales" className="staff-image"
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} 
                    />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Manuel</h3>
                        <h4 className="staff-card-surname">Corrales</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>
                <div className="staff-card" onClick={() => handleCardClick('Lorena Peña')}>
                    <img src={`${publicUrl}/media/staff_img/lorena.jpg`} alt="Lorena Peña" className="staff-image"
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} 
                    />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Lorena</h3>
                        <h4 className="staff-card-surname">Peña</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>
                <div className="staff-card" onClick={() => handleCardClick('Ivone Domínguez')}>
                    <img src={`${publicUrl}/media/staff_img/ivone.png`} alt="Ivone Domínguez" className="staff-image"
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        maxHeight: '202px',
                        objectPosition: 'center top' // Ajusta la posición de la imagen
                    }} />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Ivone</h3>
                        <h4 className="staff-card-surname">Domínguez</h4>
                        <p className="staff-card-role"><b>Teacher</b></p>
                    </div>
                </div>
            </div>

            {/* <h2 className="staff-section-title" data-aos="fade-up">Auxiliar docente / Teacher's AIDE</h2>
            <div className="staff-row" data-aos="zoom-in">
                <div className="staff-card" onClick={() => handleCardClick('Mariajosé Avena')}>
                    <img src={`${publicUrl}/media/staff_img/avena.png`} alt="Mariajosé Avena" className="staff-image" />
                    <div className="staff-info">
                        <h3 className="staff-card-name">Mariajosé</h3>
                        <h4 className="staff-card-surname">Avena</h4>
                        <p className="staff-card-role"><b>Teacher Aide</b></p>
                    </div>
                </div>
            </div> */}


            {selectedMember !== null && infoStaffDetails[selectedMember] && (
                <div
                    className="staff-modal"
                    onClick={handleModalClick} // Manejador de clic para el fondo del modal
                >
                    <div className="staff-modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="staff-modal-close" onClick={handleCloseModal}>&times;</span>
                        <img
                            src={infoStaffDetails[selectedMember].imagenUrl}
                            alt={infoStaffDetails[selectedMember].titulo}
                            className="staff-modal-img"
                            style={{
                                height: 'auto',
                                width: '100%',
                                objectFit: 'cover',
                                maxHeight: '300px',
                                objectPosition: 'center top'
                            }}
                        />
                        <h2 className="staff-modal-title">{infoStaffDetails[selectedMember].titulo}</h2>
                        <div
                            className="staff-modal-content-text"
                            dangerouslySetInnerHTML={{ __html: infoStaffDetails[selectedMember].contenido }}
                        />
                    </div>
                </div>
            )}

        </div>
    );

};

export default Staff;