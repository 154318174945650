import React from 'react';
import '../styles/WhatsAppButton.css'; // Asegúrate de que este archivo CSS exista en la misma carpeta

const SocialButtons = () => {
  const message = 'Hola, me interesa saber más sobre Speakland';
  const whatsappUrl = `https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent(message)}`;
  const messengerUrl = 'https://www.facebook.com/messages/t/362970151097615'; // Reemplaza con el usuario de Messenger
  const instagramUrl = 'https://www.instagram.com/direct/t/116586703068448'; // Reemplaza con el usuario de Instagram

  return (
    <div className="social-buttons">
      <a href={whatsappUrl} className="social-icon" target="_blank" rel="noopener noreferrer">
        <img src={`${process.env.PUBLIC_URL}/media/whatsapp.png`} alt="WhatsApp" />
      </a>
      <a href={messengerUrl} className="social-icon" target="_blank" rel="noopener noreferrer">
        <img src={`${process.env.PUBLIC_URL}/media/messenger.png`} alt="Messenger" />
      </a>
      <a href={instagramUrl} className="social-icon" target="_blank" rel="noopener noreferrer">
        <img src={`${process.env.PUBLIC_URL}/media/instagram.png`} alt="Instagram" />
      </a>
    </div>
  );
};

export default SocialButtons;
